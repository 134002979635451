:root {
  --gameboyColor: #f2ae00;
  --gameboyMaxWidth: 768px;
  --displayMargin: 1rem;
  --infoBannerTransitionTimeInSeconds: 1s;
}

html {
  background: bisque;
  touch-action: none;
}

body {
  margin: 0;
  overflow: hidden;
  color: white;
  text-align: center;
  box-sizing: border-box;
  background: transparent;
}

iframe {
  pointer-events: auto;
}

.sepia {
  -webkit-filter: sepia(40%);
  -moz-filter: sepia(40%);
  -ms-filter: sepia(40%);
  filter: sepia(40%)
}

h1 {
  position: absolute;
  width: 100%;
  font-size: 1.5rem;
}

a {
  color: white;
}

a:hover {
  color: purple;
}

canvas {
  display: block;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  position: absolute !important;
  top: 0;
  left: 0;
}

#scene-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

h1 {
  font-size: 6.2rem;
}

.popup {
  cursor: pointer;
  position: fixed;
  display: none;
  z-index: 5;
  top: 1rem;
  right: 1rem;
  background: var(--gameboyColor);
  font-family: Roboto, sans-serif;
  color: #000;
  padding: 1rem 1.6rem;
  font-weight: 900;
  font-size: 0.85rem;
  border: 3px solid black;
  border-radius: 0.4em;
  box-shadow: 0.1em 0.1em;
  pointer-events: auto;
}

.popup:hover {
  transform: translate(-0.05em, -0.05em);
  box-shadow: 0.15em 0.15em;
}

.popup:active {
  transform: translate(0.05em, 0.05em);
  box-shadow: 0.05em 0.05em;
}

.cube-menu {
  display: block;
  opacity: 100%;
  transition: 0.9s;
  height: fit-content;
  position: absolute;
  font-family: sans-serif;
  width: 100%;
  z-index: 6;
  color: white;
  bottom: 0.6rem;
  letter-spacing: 4px;
  font-size: 0.5rem;
  padding-right: 15px;
  text-transform: uppercase;

  p {
    font-size: 0.75rem;
    font-weight: bolder;
    letter-spacing: 6px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-top: 0.35rem
    }
  }
}

.info-banner {
  z-index: 2;
  position: absolute;
  bottom: 0;
  font-family: sans-serif;
  color: white;
  transition: var(--infoBannerTransitionTimeInSeconds);
  width: 100%;
  transform: translateY(100%);
  pointer-events: auto;

  .title {
    text-transform: uppercase;
    font-weight: bolder;
    letter-spacing: 6px;
    padding: 0.4rem;
    margin: 0.4rem 0;
    font-size: 0.8rem;
  }

  .content {
    font-size: 0.8rem;
    padding: 0.4rem;
    margin: 0.4rem 0;
  }
}

.help-menu {
  width: 100%;
  height: 100%;
  font-family: monospace, sans-serif;
  background: rgba(230, 230, 230, 0.95);
  font-size: 1rem;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  color: #0b0a1c;
  overflow: scroll;
  pointer-events: auto;

  .title {
    margin: 0;
    padding: 0.5rem 1rem;
    font-size: 3rem;
    text-transform: uppercase;
    border-bottom: 1px solid black;
  }

  .content {

    h3 {
      margin-top: 2em !important;
    }

    span {
      margin-top: 4rem;
      font-size: 0.5rem;
    }
  }
}

.gameboy {
  background: var(--gameboyColor);
  transition: 0.1s;
  z-index: 2;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 768px;
  margin-right: auto;
  margin-left: auto;
  height: 100%; //fallback
  height: calc(var(--vh, 1vh) * 100);
  pointer-events: none;
  overflow: hidden;
  border-left: 3px solid #2c313e;
  border-right: 3px solid #2c313e;

}

.controls {
  z-index: 3;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 1.5rem 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  pointer-events: auto;

}

.dpad {
  background: transparent;
  position: relative;
  display: inline-block;
  width: 8rem;
  height: 8rem;
  font-size: 2.5rem;
  line-height: 2.5rem;

  >* {
    width: 33%;
    height: 33%;
    position: absolute;
    background-color: #565e6a;
    cursor: pointer;

    i {
      position: relative;
      display: block;
      margin: 0 auto;
      text-align: center;
      height: 100%;
      color: #333a4a;
      text-shadow: 0px -0.5px 0px #aaa;
    }
  }

  .up {
    top: calc(0% + 4px);
    left: 33%;
    border-top: 4px solid black;
    border-left: 4px solid black;
    border-right: 4px solid black;
    border-radius: 5px 5px 0px 0px;

    &:active {
      background: linear-gradient(to top, #565e6a 0%, #333 100%);
    }

    i {
      top: -5px;
    }
  }

  .right {
    top: 33%;
    left: calc(66% - 4px);
    border-top: 4px solid black;
    border-bottom: 4px solid black;
    border-right: 4px solid black;
    border-radius: 0px 5px 5px 0px;
    box-shadow: 0px -2px 0px #888 inset;

    &:active {
      background: linear-gradient(to right, #565e6a 0%, #333 100%);
    }

    i {
      top: -5px;
    }
  }

  .down {
    top: calc(66% - 4px);
    left: 33%;
    border-left: 4px solid black;
    border-bottom: 4px solid black;
    border-right: 4px solid black;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0px -2px 0px #888 inset;

    &:active {
      background: linear-gradient(to bottom, #565e6a 0%, #333 100%);
    }
  }

  .left {
    top: 33%;
    left: calc(0% + 4px);
    border-top: 4px solid black;
    border-bottom: 4px solid black;
    border-left: 4px solid black;
    border-radius: 5px 0px 0px 5px;
    box-shadow: 0px -2px 0px #888 inset;

    i {
      top: -5px;
    }

    &:active {
      background: linear-gradient(to left, #565e6a 0%, #333 100%);
    }
  }

  .middle {
    top: 33%;
    left: 33%;
    margin: 4px;

    &::after {
      content: '';
      position: absolute;
      top: 15%;
      left: 15%;
      display: inline-block;
      border: 1px solid #6e737a;
      background: linear-gradient(to bottom, #6d7075 0%, #6d7075 30%, #23272f 70%, #23272f 100%);
      border-radius: 50%;
      height: 60%;
      width: 60%;
    }
  }

  .up-down {
    background-color: #565e6a;
    border: 4px solid black;
    border-radius: 5px;
    width: 20px;
    height: 60px;
  }

  .left-right {
    background-color: #565e6a;
    border: 4px solid black;
    border-radius: 5px;
    width: 60px;
    height: 20px;
  }
}

.display-wrapper {
  position: relative;
  transition: 0.1s;
  z-index: 2;
  box-sizing: border-box;
  padding: var(--displayMargin);
  box-shadow: 0 0 0 10rem var(--gameboyColor);
  border-radius: 6rem;
  height: 100%;
  pointer-events: none;
}

.display {
  z-index: 3;
  box-sizing: border-box;
  position: relative;
  border: var(--displayMargin) solid #23252d;
  border-radius: 4rem;
  border-bottom: 4rem solid #23252d;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}

.header {
  z-index: 3;
  position: absolute;
  display: flex;
  justify-content: center;
  margin-top: -3.5rem;
  width: calc(100% - var(--displayMargin)*2);

  svg {
    display: block;
    height: 2.5rem;
  }
}


.a-b {
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  font-family: sans-serif;

  .a,
  .b {
    margin: 0.5rem;

    padding: 0;
    position: relative;
    font-size: 2.5rem;
    line-height: 3.75rem;
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 50%;
    background-color: #2c313e;
    border: 0;
    border-bottom: 0.25rem solid #888;
    box-shadow: -0.125rem 0.125rem 10px black, 0px 0px 0.75rem black inset;
    text-shadow: 0px -0.125rem 0.125rem #888;
    color: #2c313e;
    text-align: center;
    -webkit-user-select: none;
    cursor: pointer;

    &:active>span {
      display: block;
      transform: translateY(3px);
    }

    &:active {
      box-shadow: -0.125rem 0.125rem 0.125rem black, 0px 0px 0.75rem black inset;
      border-width: 0;

    }
  }

  .a {
    top: -15px;
  }

  .b {
    margin-right: 1rem;
    top: 15px;
  }
}

.start-select {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
  align-items: center;
  font-family: sans-serif;
  pointer-events: auto;

  .select,
  .start {
    display: inline-block;
    color: #2c313e;
    text-shadow: 0px -1px 0px #2c313e;
    letter-spacing: -1px;
    width: 4rem;
    font-size: 16px;
    text-align: center;
    margin: 1rem 0 1rem 0;

    &::before {
      content: '';
      display: block;
      margin: 0 auto;
      width: 2.5rem;
      height: 10px;
      margin-bottom: 5px;
      border-radius: 40%;
      background: linear-gradient(to bottom, #0b0a1c 0%, #0b0a1c 30%, #62636c 70%, #62636c 100%);
      background-repeat: no-repeat;
      border: 2px solid #0b0a1c;
      box-shadow: 0px -2px 1px rgba(#2c313e, 0.5);
      cursor: pointer;
    }

    &:active::before {
      background: linear-gradient(to bottom, #0b0a1c 0%, #0b0a1c 50%, #62636c 100%);
    }
  }
}


@media only screen and (min-width: 768px) {
  :root {
    --displayMargin: 3rem;
  }

  .popup {
    font-size: 1.3rem;
  }

  .cube-menu {
    font-size: 0.8rem;
    bottom: 2rem;

    p {
      font-size: 1rem;
    }
  }

  .info-banner {
    margin-bottom: 0.4rem;

    .title {
      font-size: 1.2rem;
    }

    .content {
      font-size: 1rem;
    }
  }

  .controls {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

.social {
  position: absolute;
  bottom: 0px;
  right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 5px;
  z-index: 1000;
  color: black;
  font-family: monospace;
  font-weight: 600;
  font-size: larger;

  div {
    cursor: pointer;
  }

  svg {
    fill: #000;
  }

  .last-social {
    display: flex;
    max-height: 25px;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }
}